<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanKeuanganService from '@/services/LaporanKeuanganService'
//import DaftarKasService from '@/services/DaftarKasService'
import AccountService from '@/services/AccountService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanKeuanganService()
//const kasService = new DaftarKasService()
const accountService = new AccountService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)

let form = reactive({
  date_from: null,
  date_to: null,
  account: null,
})

let submitted = ref(false)
let list_account = ref([])

const rules = {
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await accountService.get()
  list_account.value = res.data.data
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  onExport()
}

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let accountId = 0
  if (form.account) accountId = form.account

  laporanService
    .exportLaporanRekapJurnal(accountId, startDate, endDate)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'rekap-jurnal.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Rekap Jurnal', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.account = null
  form.date_from = null
  form.date_to = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Rekap Jurnal"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-4">
            <Dropdown
              v-model="form.account"
              :options="list_account"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Perkiraan"
              class="w-full"
              filter
              showClear
            >
              <template #option="slotProps">
                <div>
                  <span v-if="slotProps.option.level === 2"
                    >&nbsp;&nbsp;&nbsp;</span
                  >
                  <span v-if="slotProps.option.level === 3"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <span v-if="slotProps.option.level === 4"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <small>{{ slotProps.option.kode }}</small
                  >&nbsp;{{ slotProps.option.nama }}
                </div>
              </template>
            </Dropdown>
            <!--
            <small
              v-if="
                (v$.account.$invalid && submitted) ||
                v$.account.$pending.$response
              "
              class="p-error">
              {{ v$.account.required.$message }}
            </small>
            -->
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button
              :loading="isLoadingExport"
              type="submit"
              icon="pi pi-file-pdf"
              label="Cetak"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
